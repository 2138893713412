<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-6 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getLogsOrder()"
                            v-on:keydown.enter="getLogsOrder()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số lịch sử hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getLogsOrder()" v-model="limit" class="custom-select input-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="5000">5000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable class="box-shadow" :title="'Lịch sử'" :stt="true" :columns="columnsTableLogs" :rows="data">
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" slot-scope="props" class="text-center" style="vertical-align: middle">
                <button
                    @click="removeOrder(props.row)"
                    :disabled="props.row.is_removed == 1 || props.row.is_refund == 1"
                    class="btn btn-danger btn-sm ml-2"
                >
                    <i class="far fa-trash-alt"></i> Hủy Order
                </button>
            </td>
        </datatable>
    </div>
</template>

<script>
import datatable from "../../../components/datatable/datatable"
import { getLogsOrder, removeOrder, getPriceRemoveOrder } from "../../../api/services/view.js"
export default {
    components: {
        datatable
    },
    props: {
        panel: String,
        type: String,
        provider: String
    },
    data() {
        return {
            object_id: "",
            limit: 50,
            data: [],
            columnsTableLogs: [
                { choose: true, label: "Loại", field: "type", icons: true },
                { choose: true, label: "Username", field: "client_username" },
                { choose: true, label: "ID Sedding", field: "object_id", link_link: true, link: "link" },
                { choose: true, label: "Start", field: "start", numeric: true },
                { choose: true, label: "Thời gian xem", field: "time_view", numeric: true },
                { choose: true, label: "Số lượng", field: "quantity", numeric: true },
                { choose: true, label: "Giá", field: "price_per", numeric: true },
                { choose: true, label: "Tổng tiền", field: "prices", numeric: true },
                { choose: true, label: "Đã chạy", field: "count_is_run", numeric: true },
                { choose: true, label: "Trạng thái", field: "status_message_html", html: true },
                { choose: true, label: "Ghi chú", field: "title", inputtext: true },
                { choose: true, label: "Khởi tạo", field: "created_at", timeago: true }
            ],
            priceRemoveOrder: 5000
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        }
    },
    watch: {
        panel(value) {
            if (value == "logs") {
                this.getLogsOrder()
            }
        },
        type() {
            if (this.panel == "logs") {
                this.getLogsOrder()
            }
        }
    },
    created() {
        if (this.panel == "logs") {
            this.getLogsOrder()
        }
    },
    methods: {
        async getPriceRemoveOrder() {
            let data = await getPriceRemoveOrder()
            if (data.data && data.success) {
                this.priceRemoveOrder = data.data
            }
        },
        getLogsOrder: async function() {
            let params = `?limit=${this.limit}` + (this.object_id ? "&object_id=" + this.object_id : "")
            if (!this.object_id) {
                params += this.provider ? "&provider=" + this.provider : ""
            }
            let data = await getLogsOrder(params)
            let index = 0
            if (data.data) {
                this.data = data.data.map(order => {
                    order.type = "view"
                    order.list_messages_html = ""
                    if (order.comments) {
                        for (const item of order.comments) {
                            order.list_messages_html += item.message + "\n"
                        }
                    }
                    order.object_data = order.object_data ? JSON.parse(order.object_data) : null
                    if (order.object_data) {
                        order.profile_pic_url = order.object_data.profile_pic_url
                        order.full_name = order.object_data.full_name
                    }
                    index++
                    order.index = index
                    order.instagram_username = order.instagram_username ? order.instagram_username : order.object_id
                    order.waranty = order.is_warranty
                        ? `<span class="badge badge-success py-2">Có</span>`
                        : `<span class="badge badge-warning py-2">Không</span>`
                    order.status_message_html =
                        order.object_not_exist || order.is_hidden || (order.is_refund && order.count_refund > 0)
                            ? `<span class="py-1 badge badge-danger"><i class="material-icons icon-log">info</i>${order.status_message}</span>`
                            : order.status !== 2
                            ? `<span class="py-1 badge badge-yellow"><i class="material-icons icon-log">autorenew</i>${order.status_message}</span>`
                            : `<span class="py-1 badge badge-success"><i class="material-icons icon-log">beenhere</i>${order.status_message}</span>`
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : null
                    order.color = order.is_hidden || order.object_not_exist ? true : false
                    order.is_show = false
                    order.show_detail = false
                    return order
                })
            }
        },
        removeOrder: async function(item) {
            let cancel = false
            await this.$swal({
                title: `HỦY ${item.type.toUpperCase()} ID: ${item.object_id} ?`,
                html:
                    parseInt(item.status) === 1
                        ? "Bạn chắc chắn muốn hủy Order?"
                        : `Sau khi hủy bạn sẽ được hoàn lại số lượng ${item.type.toUpperCase()} chưa tăng sau 1 đến 3 ngày và trừ thêm ${
                              this.priceRemoveOrder
                          } ${this.currency} phí để tránh Spam !`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không"
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                let data = await removeOrder(item.id)
                if (data.status === 200) {
                    let index = this.data.findIndex(el => el.id === item.id)
                    this.data.splice(index, 1)
                    this.$toastr.success("ID Seeding: " + item.object_id + " - " + data.message)
                } else {
                    this.$toastr.error("ID Seeding: " + item.object_id + " - " + data.message)
                }
            }
        }
    }
}
</script>
