import { request } from "../../utils/request"

export function createOrder(data) {
    return request({
        url: "api/view/create",
        method: "post",
        data
    })
}
export function getPriceRemoveOrder() {
    return request({
        url: "api/advertising/view/get-price-destroy",
        method: "get"
    })
}
export function getLogsOrder(params) {
    return request({
        url: "api/view" + params,
        method: "get"
    })
}
export function removeOrder(id) {
    return request({
        url: "api/view/remove/" + id,
        method: "get"
    })
}
