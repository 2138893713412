<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <form v-on:submit.prevent="createOrder()">
                            <div class="form-group row">
                                <label for="object_id" class="col-sm-2 col-form-label bold"
                                    >Nhập Link cần tăng View:</label
                                >
                                <div class="col-sm-10">
                                    <input
                                        v-model="object_id"
                                        type="text"
                                        class="form-control form-control-md input-light"
                                        id="object_id"
                                        placeholder="Nhập Link cần tăng View"
                                        required
                                    />
                                </div>
                            </div>
                            <template>
                                <div class="form-group row justify-content-end mb-0">
                                    <div class="col-sm-10">
                                        <div class="quote-red-2 alert alert-danger">
                                            <p class="cl-red font-bold">
                                                - Lưu ý: Hiện tại tính năng này đang thử nghiệm nên chưa ổn đinh, có thể
                                                lên không đủ hoặc lên chậm, vui lòng buff dư thêm 30 - 50% trên tổng số
                                                lượng để tránh tụt hoặc chọn gói bảo hành để được hoàn tiền nếu tụt.
                                            </p>
                                            <p class="mb-0">
                                                Hệ thống <span class="bold">KHÔNG BẢO HÀNH</span> dịch vụ này, vui lòng
                                                cài đặt số lượng nhỏ để test trước, tránh không tăng được mất tiền !
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label bold">Số lượng cần tăng</label>
                                    <div class="col-sm-10">
                                        <input
                                            v-model="quantity"
                                            type="number"
                                            class="form-control bg-gray border-0 form-control-md"
                                            min="0"
                                            required
                                        />
                                    </div>
                                </div>
                            </template>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label bold">Thời gian xem video:</label>
                                <div class="col-sm-10">
                                    <select class="col-xl-12 input-light form-control" v-model="time_view">
                                        <option
                                            v-show="index > 3"
                                            v-for="(option, index) in 120"
                                            :value="option"
                                            :key="index"
                                            >{{ option }} Giây</option
                                        >
                                    </select>
                                    <div class="quote-green-3 alert alert-primary mt-4 mb-0">
                                        <h6 class="bold cl-red">
                                            Giá tiền 1 view = {{ price_default }} {{ currency }} x (thời gian xem video)
                                        </h6>
                                        <p class="cl-green font-bold mb-0">
                                            Mẹo nhỏ: Thời gian xem càng dài thì View càng chất lượng, vui lòng tùy chỉnh
                                            cho phù hợp với mục đích của bạn!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label bold">Ghi chú</label>
                                <div class="col-sm-10 text-center">
                                    <input
                                        v-model="notes"
                                        type="text"
                                        placeholder="Nhập ghi chú về tiến trình của bạn"
                                        class="form-control bg-gray border-0 form-control-md"
                                        min="0"
                                    />
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-12 text-center bold">
                                    <div class="card card-info">
                                        <div class="card-body">
                                            <h5 class="font-bold">
                                                Tổng:
                                                <span class=" bold"
                                                    ><number
                                                        ref="number1"
                                                        :from="0"
                                                        :to="totalPrice"
                                                        :format="theFormat"
                                                        :duration="1"
                                                        :delay="0"
                                                        easing="Power1.easeOut"
                                                    />
                                                    {{ currency }}</span
                                                >
                                            </h5>
                                            <h6 class="mb-0">
                                                Bạn sẽ buff <span class="">{{ quantity }} view</span> với giá
                                                <span class=""
                                                    >{{ time_view * price_default }} {{ currency }} / 1 View</span
                                                >
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-12 text-center bold">
                                    <button type="submit" class="btn btn-block btn-dark-blue btn-lg btn-rounded bold">
                                        Tạo tiến trình
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4">
                        <div v-if="type != 'view'" class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Thời gian làm việc (tăng seeding) và bảo hành tính từ ngày bắt đầu cho đến ngày
                                    kết thúc job, tối đa là 1 tuần
                                </p>
                                <p>
                                    - Hết thời gian của job đã order nếu không đủ số lượng hệ thống sẽ tự động hoàn lại
                                    số tiền seeding chưa tăng cho bạn <b class="font-bold">trong vòng 1 đến 3 ngày</b>
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id bài viết, công khai và check kỹ job tránh tạo nhầm, tính năng
                                    đang trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p v-if="type == 'like_page'" class="font-bold">
                                    - Không tăng Like cho Page có thời gian tạo dưới 1 tháng.
                                </p>
                                <p v-else>
                                    - Chỉ nhận id bài viết công khai không nhập id album, id comment, id trang cá nhân,
                                    id page,...
                                </p>
                                <p class="font-bold">
                                    - Nhập id lỗi hoặc trong thời gian chạy die id thì hệ thống không hoàn lại tiền.
                                </p>
                                <p>
                                    - Mỗi id có thể
                                    <span class="font-bold">Buff tối đa 10 lần trên hệ thống để tránh Spam</span>, max
                                    1k trong ngày (hoặc hơn nếu order giá cao), trên 1k thời gian lên chậm hơn trong
                                    vòng 7 ngày
                                </p>
                            </div>
                        </div>
                        <div v-else class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <strong
                                    ><b><i class="mr-1"></i>Hướng dẫn: </b></strong
                                >
                                <br />- Số lượng view tối thiểu mỗi lần mua là 500 view , tối đa là 100,000 View.
                                <br />- Hệ thống tăng View và hỗ trợ tăng mắt LiveStream trong quá trình chạy View.
                                <br />- Để giống người xem thật, số lượng View sẽ được điều chỉnh lên xuống ngẫu nhiên
                                quanh số View của bạn mua.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <viewlogs :provider="provider" :panel="panel" :type="type"></viewlogs>
            </div>
        </div>
    </div>
</template>
<script>
import menus from "../../../config/menu"
import { createOrder } from "../../../api/services/view"
import { catchError, facebookPostUrlToId, getYoutubeID } from "../../../helpers"
import viewlogs from "./view-logs"
export default {
    components: {
        viewlogs
    },
    data() {
        return {
            menus: menus,
            time_view: 5,
            quantity: 100,
            price: 1,
            price_default: 1,
            object_id: "",
            notes: "",
            link: "",
            panel: "",
            type: "",
            provider: ""
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 90)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return price
        },
        totalPrice() {
            return this.priceSingle * this.quantity * this.time_view
        },
        router() {
            return this.$route.path
        }
    },
    created() {
        this.returnType(this.router)
        this.getPriceSeedingType(this.type)
    },
    watch: {
        async object_id(value) {
            if (value && !this.link) {
                if (this.object_id.includes("tiktok.com") && this.type == "tiktok_view") {
                    this.object_id = await this.getPostTiktokId(this.object_id)
                } else if (
                    ((this.object_id.includes("youtube") && this.type == "youtube_view") ||
                        this.object_id.includes("youtu.be")) &&
                    this.type == "youtube_view"
                ) {
                    this.link = this.object_id
                    this.object_id = getYoutubeID(this.object_id)
                    this.$toastr.success("Đã tự động get id cho bạn")
                } else if (
                    (this.type == "fb_view" && this.object_id.includes("facebook.com")) ||
                    !isNaN(this.object_id)
                ) {
                    this.object_id = facebookPostUrlToId(this.object_id)
                    this.link = "https://www.facebook.com/" + this.object_id
                } else if (!this.link) {
                    this.object_id = ""
                    this.$toastr.error("Bạn chọn menu Buff không đúng, vui lòng chọn lại!")
                }
            } else if (!value) {
                this.link = ""
            }
        },
        router(value) {
            this.returnType(value)
        },
        type(value) {
            this.object_id = ""
            this.link = ""
            this.getPriceSeedingType(value)
        }
    },
    methods: {
        returnType(value) {
            if (value.includes("facebook")) {
                this.type = "fb_view"
                this.provider = "facebook"
            } else if (value.includes("youtube")) {
                this.type = "youtube_view"
                this.provider = "youtube"
            } else {
                this.provider = "tiktok"
                this.type = "tiktok_view"
            }
        },
        createOrder: async function() {
            this.$swal({
                title: `Order View ${this.provider.toUpperCase()}`,
                text: `Bạn có chắc chắn muốn Order ${this.quantity} View với giá: ${this.price} ${this.currency}/1 View ?`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không"
            }).then(result => {
                if (result.value) {
                    this.orderConfirm()
                }
            })
        },
        orderConfirm: async function() {
            let postData = {
                link: this.link,
                provider: this.provider,
                object_id: this.object_id,
                quantity: this.quantity,
                time_view: this.time_view,
                title: this.notes && this.notes.length > 0 ? this.notes : "Video"
            }
            let data = await createOrder(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        getPostTiktokId: async function(url) {
            this.link = url.split("?")[0]
            let result = ""
            if (url.indexOf("tiktok.com") < 0) {
                result = url
            } else {
                let postId = (postId = url.match(/(.*)\/video\/(.*)?/))
                if (postId) {
                    result = postId[2].split("?")[0].split("&")[0]
                }
            }
            if (this.link.indexOf("www.tiktok.com") < 0) {
                this.link = this.link.replace("tiktok.com", "www.tiktok.com")
            }
            this.link = this.link.trim()
            if (result) {
                this.$toastr.success("Đã tự động get id cho bạn")
            } else {
                this.$toastr.error("Get ID thất bại, hãy thử Get ID bằng Findids.net")
            }
            return result
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        handleShowPanel: function(panel) {
            this.panel = panel
        },
        getPriceSeedingType: async function(type) {
            let price = this.prices.find(price => price.package_name == type)
            if (price) {
                this.price_default = price.price
                this.price = price.price
            }
        }
    }
}
</script>
